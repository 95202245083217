import React from 'react';
import styled from 'styled-components';

const Loader = () => {
  return (
    <Container></Container>
  );
}



const Container = styled.div`

  border: 10px solid #000844;
  margin: 0 auto;
  margin-top: 4rem;
  border-radius: 50%;
  border-top: 10px solid #737ED1;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export default Loader;

