import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-family: 'Avenir';
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: inheret;
    text-decoration: none;
  }

  @media (max-width: 900px) {
    html, body {
      font-size: 14px;
    }
  }

`;


export default GlobalStyle;

