import React from 'react';
import styled from 'styled-components';

import backgroundImage from 'assets/logo.png';


const Navbar = () => {
  return (
    <Container img={backgroundImage}>
      <nav>
        <div className="left">
          <img src={backgroundImage} alt="Steno Perfect Practice Program" />
          <div className="logo-txt-container">
            <div>Perfect</div>
            <div>Practice</div>
            <div>Program</div>
          </div>
        </div>
        <div className="right">
        </div>
      </nav>
    </Container>
  );
}


const Container = styled.div`
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;

  color: white;

  nav {
    width: 100%;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
  }

  .logo-txt-container {
    user-select: none;
    color: #005C9D;
    font-weight: 100;
    font-size: 2rem;
    line-height: 0.9;
    div:nth-child(2) { font-weight: bold; }
  }

  nav .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    img { max-width: 150px; }
  }

  .background {
    position: absolute;
    background-image: linear-gradient(to right, rgba(0,8,68,0.8), rgba(0,0,0,0));
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  button {
    padding: 0.5rem;
    background: #FFFFFF;
    border: 3px solid #FFB800;
    border-radius: 9px;
  }

  @media (max-width: 900px) {
    .logo-txt-container {
      font-size: 2.5rem;
      line-height: 1;
    }
  }

`;



export default Navbar;

