import React, { useState, useEffect, useLayoutEffect } from 'react';
import Calendar from 'react-calendar';
import { format } from 'date-fns';
import 'react-calendar/dist/Calendar.css';
import { getThisWeek, getThisMonth, getThisYear, convertYMD } from 'utils/date-utils';
import styled from 'styled-components';
import calendar from 'assets/icons/calendar-black.svg';


const LeaderboardParams = ({ showData, setShowData, data, dateFrom, setDateFrom, dateTo, setDateTo }) => {
  const [ nameFilter, setNameFilter ] = useState('');
  const [ dateSelection, setDateSelection ] = useState('Custom Range');
  const [ dropDownActive, setDropDownActive ] = useState(false);

  const handleChange = (event) => {
    if (event.target.className === 'name-filter') setNameFilter(event.target.value);
  }

  const handleDateSelection = (selection) => {
    setDateSelection(selection);
    let tmp = {};
    if (selection === 'This Week') tmp = getThisWeek();
    if (selection === 'This Month') tmp = getThisMonth();
    if (selection === 'This Year') tmp = getThisYear();
    if (selection === 'Custom Range') return;



    const { firstDate, lastDate } = tmp;
    setDateFrom(firstDate);
    setDateTo(lastDate);
    return;
  }

  useEffect(() => {
    if (nameFilter === '') {
      setShowData(data);
    } else {
      setShowData(prev => {
        return [...data].filter(row => row.username.toUpperCase().includes(nameFilter.toUpperCase()));
      });
    }
  }, [nameFilter, data, setShowData]);

  useLayoutEffect(() => {

    if (dateSelection !== 'Custom Range') {
      function outsideClick() {
        setDropDownActive(false);
      }

      document.querySelector('.body').addEventListener('click', outsideClick);
      return () => document.querySelector('.body').removeEventListener('click', outsideClick);
    }

    return;

  }, [dropDownActive, dateSelection]);

  return (
    <Container dropDownActive={dropDownActive} dateFrom={dateFrom} dateTo={dateTo}>
      <input className="name-filter" type="text" name="name" placeholder="filter by name" onChange={handleChange} />
      <div className="dropdown" name="date">
        <div className="left" onClick={() => setDropDownActive(!dropDownActive)}><span><img src={calendar} alt="select date"/></span> Date</div>
        <div className="right">
          <div>{ dateSelection }</div>
          <div className="dropdown-content">
            <div className="dropdown-item" onClick={() => handleDateSelection('This Year')}>This Year</div>
            <div className="border"></div>
            <div className="dropdown-item" onClick={() => handleDateSelection('This Month')}>This Month</div>
            <div className="border"></div>
            <div className="dropdown-item" onClick={() => handleDateSelection('This Week')}>This Week</div>
            <div className="border"></div>
            <div className="dropdown-item" onClick={() => handleDateSelection('Custom Range')}>Custom Range</div>
            <div className="calendar" style={{ display: dateSelection === 'Custom Range' ? 'flex' : 'none' }}>
              <Calendar 
                value={[ convertYMD(dateFrom), convertYMD(dateTo) ]}
                selectRange={true}
                allowPartialRange={false}
                calendarType="US"
                onChange={(value, event) => {
                  setDateFrom( format(value[0], 'yyyy-MM-dd' ));
                  setDateTo( format(value[1], 'yyyy-MM-dd') );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}


const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0;

  .name-filter {
    width: 30%;
    min-height: 36px;
    background: #FFFFFF;
    padding: 0.3rem 1rem;
    border: 1px solid #979797;
    border-radius: 11px;
  }
  
  .date-filter {
    width: 30%;
    min-height: 36px;
    padding: 0.3rem 1rem;
    border: 1px solid #979797;
    border-radius: 11px;
  }

  .submit-btn {
    background: #005C9D;
    border-radius: 11px;
    color: white;
    padding: 0.3rem 2rem;
    border: none;
    user-select: none;
    transition: all 0.3s ease;

    &:hover {
      background-color: #6099C2;
    }

  }

  .dropdown {
    display: flex;
    position: relative;
    border: 1px solid #979797;
    border-radius: 11px;
    margin: 0; padding: 0;
    min-width: 250px;
    height: 40px;
    cursor: pointer;
    user-select: none;
    background-color: white;
    span { margin-right: 5px; margin-bottom: 5px; }


    .left, .right { height: 100%; }

    .left {
      display: flex; justify-content: flex-begin; align-items: center;
      position: relative;
      padding-left: 15px;
      background: #FFC85B;
      border-radius: 11px 0 0 11px;
      width: 40%;
      font-family: Avenir-Heavy;
      font-size: 16px;
      letter-spacing: 0;

    }

    .right {
      display: flex; justify-content: flex-begin; align-items: center;
      padding-left: 15px;
      border-radius: 11px 0 0 11px;
      width: 60%;
      font-family: Avenir-Heavy;
      font-size: 16px;
      color: #0091FF;
      letter-spacing: 0;

      
      &::after {
        content: '';
        transition: 0.6s opacity ease;
        opacity: 0;
        transform: translateX(0%);
        max-width: 0%;
        width: 100%;
      }

      &:hover::after {
        content: '${props => {
          function customFunc(dte) {
            const dte_arr = dte.split('-');
            return dte_arr[1] + '/' + dte_arr[2] + '/' + dte_arr[0];
          }
          return customFunc(props.dateFrom) + ' - ' + customFunc(props.dateTo);
        }}';
        position: absolute;
        background-color: white;
        display: flex; align-items: center;
        max-width: 100%;
        height: 100%;
        opacity: 1;
      }

    }

  }

  .dropdown-content {
    display: ${props => props.dropDownActive ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    position: absolute;
    text-decoration: none;
    z-index: 5;
    background-color: white;
    width: 100%;
    text-align: center;
    top: 100%;
    left: 0;

    .dropdown-item {
      margin: 5px 0;
    }

    .border {
      border: 1px solid #979797;
      height: 1px;
      width: 80%;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    width: 100;
    justify-content: start;
    align-items: flex-start;

    .name-filter, .dropdown, .submit-btn { margin: 1rem 0; }

    .name-filter { width: 100%; }
    .dropdown { width: 100%; }
    .submit-btn { width: 100%; }

  }

`;


export default LeaderboardParams;

