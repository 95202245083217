import React, { useState, useEffect } from 'react';
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { Routes, Route } from 'react-router-dom';
import GlobalStyle from 'styles/GlobalStyles';
import styled from 'styled-components';


// components
import Leaderboard from 'components/Leaderboard';
import LeaderboardParams from 'components/LeaderboardParams';
import Navbar from 'components/Navbar';
import Loader from 'components/Loader';

import api from './api';

import background from 'assets/background.jpg';

import paginate from 'utils/pagination';


const App = () => {
  const today = new Date();

  const [ data, setData ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ showData, setShowData ] = useState([]);
  const [ dateFrom, setDateFrom ] = useState( format(startOfWeek(today, { weekStartsOn: 1 }), 'yyyy-MM-dd') );
  const [ dateTo, setDateTo ] = useState( format(endOfWeek(today, { weekStartsOn: 1 }), 'yyyy-MM-dd') );
  const [ page, setPage ] = useState(1);
  const [ paginationActive, setPaginationActive ] = useState(false);

  const pageSize = 20;

  const handlePage = (action) => {
    if (action === 'prev') {
      setPage(prevState => {
        if (prevState <= 1) return prevState;
        return prevState - 1;
      });
    }
    if (action === 'next') {
      setPage(prevState => {
        if (prevState >= Math.ceil(data.length / pageSize)) return prevState;
        return prevState + 1;
      })
    }
  }

  useEffect(() => {
    setShowData( paginate(data, pageSize, page) )
  }, [data, page])

  useEffect(() => {
    api.get('/leaderboard', { params: { dateFrom: dateFrom, dateTo: dateTo } })
      .then(res => {
        const dta = res.data.data.map((d, i) => {
          return { ...d, rank: i+1 };
        });
        setData(dta);
        setShowData(dta);

        if (dta.length >= pageSize) {
          setPaginationActive(true);
          setShowData( dta.slice(0, pageSize) )
        }

        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      })
  }, [ dateFrom, dateTo ]);


  return (
    <Container>

      <GlobalStyle />
      <Navbar />

        <Routes>

          <Route index path="/" element={
            <div className="body" style={{ width: "100%" }}>
              <Body background={background} paginationActive={paginationActive}>
                <LeaderboardParams showData={showData} setShowData={setShowData} data={data} dateFrom={dateFrom} setDateFrom={setDateFrom} dateTo={dateTo} setDateTo={setDateTo}/>
                <ContainerLeaderboard>
                  { loading ? (
                    <Loader />
                  ) : (
                    <Leaderboard data={showData} />
                  ) }
                </ContainerLeaderboard>
                <div className="pagination">
                  <button onClick={() => handlePage('prev')}>Prev</button>
                  <div className="page-num">{ page } of { Math.ceil( data.length / pageSize ) }</div>
                  <button onClick={() => handlePage('next')}>Next</button>
                </div>
                <div className="background"></div>
              </Body>
            </div>
          } />

        </Routes>


    </Container>
  );
}



const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;


const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  position: relative;
  z-index: 1;
  height: 100vh;

  .background {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    opacity: 0.3;
    left: 0;
    z-index: -1;
    overflow: hidden;
    background-image: url('${props => props.background}');
  }

  .pagination {
    display: ${props => props.paginationActive ? 'flex' : 'none'};
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 350px;
    margin: 1rem 0;
    button {
      user-select: none;
      border: none;
      border-radius: 8px;
      background-color: #005C9D;
      color: white;
      padding: 0.2rem 1rem;
      transition: all 0.3s ease;

      &:hover {
        background-color: #6099C2;
      }

    }
  }

  @media(max-width: 900px) {
    .pagination {
      max-width: 100%;
    }
  }


`;

const ContainerLeaderboard = styled.div`
  width: 100%;
  overflow-x: auto;
`;


export default App;

