import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  format
} from 'date-fns'



export const getThisMonth = () => {
  const date = new Date();
  const firstDate = format( startOfMonth( date ), 'yyyy-MM-dd' );
  const lastDate = format( endOfMonth( date ), 'yyyy-MM-dd' );
  return { firstDate, lastDate };
}

export const getThisWeek = () => {
  const date = new Date();
  const firstDate = format( startOfWeek( date ), 'yyyy-MM-dd' );
  const lastDate = format( endOfWeek( date ), 'yyyy-MM-dd' );
  return { firstDate, lastDate };
}

export const getThisYear = () => {
  const date = new Date();
  const firstDate = format( startOfYear( date ), 'yyyy-MM-dd' );
  const lastDate = format( endOfYear( date ), 'yyyy-MM-dd' );
  return { firstDate, lastDate };
}

export const convertYMD = (dateStr) => {
  let datearr = dateStr.split('-');
  const newDate = new Date(parseInt(datearr[0]), parseInt(datearr[1])-1, parseInt(datearr[2]))
  return newDate;
}
