import React from 'react';
import styled from 'styled-components';


const Leaderboard = ({ data }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Rank #</th>
          <th>Name</th>
          <th>Most Recent Lesson</th>
          <th>Iterations</th>
          <th>Days</th>
        </tr>
      </thead>
      <tbody>

        { data.map(row => (
          <tr key={ row.userid }>
            <td className="fitwidth">#{ row.rank }</td>
            <td className="fitwidth">{ row.username }</td>
            <td className="fitwidth">{ row.mostrecentlessonscompleted }</td>
            <td className="fitwidth">{ row.lessonspracticed }</td>
            <td className="fitwidth">{ row.dayspracticed }</td>
          </tr>
        )) }

      </tbody>
    </Table>
  );
}


const Table = styled.table`
  width: 100%;
  border: solid 1px black;

  th {
    text-align: center;
    background-image: linear-gradient(180deg, #FFFEFE 0%, #CBCBCB 100%);
  }

  td {
    border-right: 1px solid black;
    border-left: 1px solid black;
    padding: 0.5rem 2rem;
  }

  .fitwidth {
    width: 1px;
    white-space: nowrap;
  }

`;


export default Leaderboard

