import axios from 'axios';

const endpoint = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://ppleaderboardbackend.azurewebsites.net'

const api = axios.create({
  baseURL: endpoint,
  headers: {
    'Authorization': 'Bearer 01838308'
  }
});


export default api

